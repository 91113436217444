import { EmptyState } from '@/components/EmptyState';
import { SearchInput } from '@/components/SearchInput';
import { Spinner } from '@/components/Spinner';
import { faFileExport, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Folder, File as OSFile, User } from '@types';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Menu, Modal } from 'react-daisyui';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import DragAndDrop from '@/components/DragAndDrop/DragAndDrop';
import { IUserContext, useUserContext } from '@/context/UserContext';
import { alertMessageSuccess } from '@/utils/alerts';
import { get, postFiles } from '@/utils/api';
import useFirms from '@/hooks/useFirms';
import { FolderOrFileComponent } from './components/FileDisplay';
import { GrantPermission, ModalReportGeneration } from './modals';
import { getFormDataSignal, setFormDataSignal } from './utils/formDataSignal';
import { FolderNavPath } from './components/FolderNavPath';
import type { FilePermissions, FileSearchItem } from './types';

import {
  buildSearchMap,
  getFolderStackFromPath,
  getPathFromFolderStack,
  getPathIdsFromFolderStack,
  isPathFromStack,
  searchFiles,
  shouldDisplayFileItem,
} from './utils';

import useFileData from '../../hooks/useFileData';
import { FileSearchResults } from './components/FileSearchResults';

export default function FileBrowser({
  rootFolder,
  isLoading = false,
}: {
  rootFolder?: Folder;
  isLoading?: boolean;
}) {
  const { auth, impersonatingAccountId }: IUserContext = useUserContext(); // Use the context
  const { refreshFileData } = useFileData();
  const [searchParams, setSearchParams] = useSearchParams();
  const urlQuery = searchParams.get('q') || '';
  const { useGetFirmsClients } = useFirms();

  const [folderStack, setFolderStack] = useState<Folder[]>(
    rootFolder ? [rootFolder] : []
  );
  const [currentFile, setCurrentFile] = useState<OSFile | null>(null);
  const [showGrantPermission, setShowGrantPermission] =
    useState<boolean>(false);
  const [showDragAndDrop, setShowDragAndDrop] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchHits, setSearchHits] = useState<FileSearchItem[] | null>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [currentFolderPath, setCurrentFolderPath] = useState('');
  const [currentFolderIdPath, setCurrentFolderIdPath] = useState('');
  const pathParams = useParams();
  const navigate = useNavigate();
  const pathStack = useMemo(
    () => pathParams['*']?.split('/') ?? [],
    [pathParams]
  );
  const clientId = impersonatingAccountId || auth?.clients?.[0]?.id || 0;
  const { data: firmsClientsData } = useGetFirmsClients(clientId);
  const firmUserIds = firmsClientsData
    ?.flatMap((firmClient) => firmClient.firm?.firm_users)
    .map((firmUser) => firmUser?.user_id);

  const [folderToUpload, setFolderToUpload] = useState<Folder | null>(null);
  const [folderToReportGeneration, setFolderToReportGeneration] =
    useState<Folder | null>(null);
  const [errorUploading, setErrorUploading] = useState<boolean>(false);

  useEffect(() => {
    if (rootFolder && !folderStack.length) {
      setFolderStack([rootFolder]);
      const path = getPathFromFolderStack([rootFolder]);
      const idsPath = getPathIdsFromFolderStack([rootFolder]);
      setCurrentFolderPath(path);
      setCurrentFolderIdPath(idsPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootFolder]);

  useEffect(() => {
    if (pathStack.length && rootFolder) {
      // if the folder stack doesn't match the url path, update it
      const shouldUpdateStack = !isPathFromStack(pathStack, folderStack);
      if (shouldUpdateStack) {
        const newStack = getFolderStackFromPath(pathStack, rootFolder);
        setFolderStack(newStack);
        const path = getPathFromFolderStack(newStack);
        const pathIds = getPathIdsFromFolderStack(newStack);
        setCurrentFolderPath(path);
        setCurrentFolderIdPath(pathIds);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathStack, rootFolder]);

  const handleUpdateFolderStack = (newFolder: Folder[] | Folder) => {
    setCurrentFile(null);

    // if the argument is a single folder, add it to the stack
    const newStack = Array.isArray(newFolder)
      ? newFolder
      : [...folderStack, newFolder];

    setFolderStack(newStack);
    const path = getPathFromFolderStack(newStack);
    const pathIds = getPathIdsFromFolderStack(newStack);
    setCurrentFolderPath(path);
    setCurrentFolderIdPath(pathIds);
    navigate(path);
    return path;
  };

  const searchMap = useMemo(
    () => rootFolder && buildSearchMap(rootFolder),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rootFolder, currentFile]
  );

  const handleSearchQueryChange = useCallback(
    (newQuery: string) => {
      setSearchQuery(newQuery);
      if (newQuery.length < 2) {
        setSearchHits(null);
        setSearchParams({}, { replace: true });
        return;
      }
      if (searchMap) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        currentFile && setCurrentFile(null);
        const hits = searchFiles(
          searchMap,
          newQuery,
          auth?.isClient || !!impersonatingAccountId
        );
        setSearchHits(hits);
        setSearchParams({ q: newQuery }, { replace: true });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchMap]
  );

  useEffect(() => {
    if (rootFolder && urlQuery !== searchQuery) {
      handleSearchQueryChange(urlQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlQuery, rootFolder]);

  const handleSearchClick = (clicked: FileSearchItem) => {
    const path = clicked.path.split('/');
    if (rootFolder) {
      const newStack = getFolderStackFromPath(path, rootFolder);
      setFolderStack(newStack);
    }
    navigate(`/files${clicked.path}`);
    setSearchQuery('');

    if (clicked.type === 'file') {
      setCurrentFile(clicked as OSFile);
    }
  };

  const currentFolder = useMemo(() => {
    if (pathStack.length && rootFolder) {
      const newStack = getFolderStackFromPath(pathStack, rootFolder);
      return newStack?.[newStack.length - 1];
    }
    return folderStack?.[folderStack.length - 1];
  }, [folderStack, rootFolder, pathStack]);

  if (currentFolder?.files) {
    currentFolder.files.sort((a, b) => {
      if (a.type !== b.type) {
        return a.type === 'folder' ? -1 : 1;
      }
      if (a.type === 'folder') {
        return a.name.localeCompare(b.name);
      }
      return (
        new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
      );
    });
  }

  const showSearchHits = !currentFile && searchQuery.length > 1 && searchHits;
  const showFolderList = !showSearchHits;
  const showFolderNav = !showSearchHits;

  const handdleOpenUpload = (folder: Folder) => {
    setErrorUploading(false);
    setShowDragAndDrop(true);
    setFolderToUpload(folder);
  };

  const stripWebPath = (url: string) => {
    const startingStringToRemove = '/External';
    const folderName = decodeURI(url);

    if (folderName.indexOf(startingStringToRemove) === -1) {
      return 'External';
    }

    return folderName.substring(folderName.indexOf(startingStringToRemove) + 1);
  };

  const uploadFiles = async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      await postFiles('/files/upload', getFormDataSignal() as FormData, {
        timeout: 60000,
      })
        .then(async (resp: Response) => {
          if (resp.status === 200) {
            alertMessageSuccess(
              'Files Successfully Uploaded! - OpStart team review in progress.'
            );
            setShowDragAndDrop(false);
            setFolderToUpload(null);
            setFormDataSignal(null);
            await get('files/syncTempFiles');
            if (impersonatingAccountId) {
              await refreshFileData(impersonatingAccountId);
            } else {
              await refreshFileData();
            }
          } else {
            setErrorUploading(true);
          }
        })
        .catch(() => {
          setErrorUploading(true);
        })
        .finally(() => setIsUploading(false));
    } catch (_err) {
      setErrorUploading(true);
    }
  };

  const handleSummit = async (files: File[]) => {
    setErrorUploading(false);
    setIsUploading(true);
    const formData = new FormData();
    const folderName = stripWebPath(folderToUpload?.webUrl as string);
    formData.append('directoryName', folderName);
    formData.append('teamId', folderToUpload?.teamId as string);
    files.forEach((file) => {
      formData.append('', file, encodeURIComponent(file.name));
    });
    setFormDataSignal(formData);
    // The auth data might not be updated
    const userData = await get<User>(`users/${auth?.id as number}`);
    const folderHasBeenShared = folderToUpload?.permissions?.some(
      (permission) => {
        // We aren't using the feature for tags right now.
        // if (
        //   (Array.isArray(permission.allowed_tags_by_user_id) &&
        //     permission.allowed_tags_by_user_id.length > 0) ||
        //   (Array.isArray(permission.allowedUsers) &&
        //     permission.allowedUsers.length > 0)
        // ) {
        if (
          Array.isArray(permission.allowedUsers) &&
          permission.allowedUsers.length > 0
        ) {
          return true;
        }
        return false;
      }
    );
    const showPermissionsModal =
      userData.primary && auth?.isClient && folderHasBeenShared;
    setShowGrantPermission(showPermissionsModal || false);
    setShowDragAndDrop(!showPermissionsModal);
    if (!showPermissionsModal) {
      if (auth?.isInvestor) {
        const permissions: FilePermissions = {
          allowedUsers: [auth.id],
          allowedTags: [],
          parentFolderId: folderToUpload?.id || '',
          allowedFirms: [],
        };
        formData.append('permissions', JSON.stringify(permissions));
      }
      await uploadFiles();
    }
  };
  const showReportGeneration =
    currentFolder?.name?.toLocaleLowerCase().includes('financial statements') &&
    auth?.isSuperAdmin;

  const showUploadCurrentFolder =
    currentFolder &&
    !['shared-files', 'all-clients'].includes(currentFolder.id);

  function handleReportClick(actualFolder: Folder): void {
    setFolderToReportGeneration(actualFolder);
  }

  return (
    <>
      <ToastContainer />
      <ModalReportGeneration
        folder={folderToReportGeneration}
        setFolder={setFolderToReportGeneration}
      />
      {auth?.isClient && showGrantPermission && folderToUpload ? (
        <GrantPermission
          isOpen={showGrantPermission}
          onClose={() => {
            setShowGrantPermission(false);
            setShowDragAndDrop(false);
            setFolderToUpload(null);
          }}
          folder={folderToUpload}
          onSave={uploadFiles}
          firmClientsData={firmsClientsData}
          firmUserIds={firmUserIds as number[]}
        />
      ) : null}
      {Boolean(folderToUpload) && showDragAndDrop ? (
        <Modal
          className='w-11/12 max-w-5xl'
          onClickBackdrop={() => {
            setShowDragAndDrop(false);
            setFolderToUpload(null);
          }}
          open={Boolean(folderToUpload)}
        >
          <Button
            size='sm'
            color='ghost'
            shape='circle'
            className='absolute right-2 top-2'
            onClick={() => {
              setShowDragAndDrop(false);
              setFolderToUpload(null);
            }}
          >
            x
          </Button>
          <Modal.Header>Upload files to {folderToUpload?.name}</Modal.Header>
          <Modal.Body>
            <DragAndDrop
              handleSubmit={handleSummit}
              isLoading={isUploading}
              errorUploading={errorUploading}
            />
          </Modal.Body>
        </Modal>
      ) : null}
      <div className='grid grid-cols-2'>
        <div>
          {/* Search */}
          <SearchInput
            className='mb-2 ml-1'
            value={searchQuery}
            onChange={handleSearchQueryChange}
          />
        </div>

        {showUploadCurrentFolder ? (
          <div className='text-right pt-2'>
            {showReportGeneration ? (
              <Button
                color='accent'
                id='upload'
                data-testid='current-folder-upload-button'
                size='sm'
                className='mr-2'
                onClick={() => handleReportClick(currentFolder)}
              >
                <FontAwesomeIcon
                  icon={faFileExport}
                  size='lg'
                  className='mr-2 text-white'
                />
                Generate Report
              </Button>
            ) : null}
            {!auth?.isInvestor ? (
              <Button
                color='accent'
                id='upload'
                data-testid='current-folder-upload-button'
                size='sm'
                onClick={() => {
                  setShowDragAndDrop(true);
                  setFolderToUpload(currentFolder);
                }}
              >
                <FontAwesomeIcon
                  icon={faUpload}
                  size='lg'
                  className='mr-2 text-white'
                />
                Send Files
              </Button>
            ) : null}
          </div>
        ) : null}
      </div>
      <div
        className={`rounded-2xl bg-base-200 relative flex flex-col overflow-y-auto w-full overflow-x-hidden
        min-h-36 max-h-full`}
        data-testid='file-browser'
      >
        {!rootFolder && isLoading ? (
          <Spinner className='mx-auto w-24 my-10' />
        ) : null}

        {!rootFolder && !isLoading ? <EmptyState name='files' /> : null}

        {currentFolder &&
        currentFolder.files &&
        currentFolder.files.length < 1 ? (
          <>
            <div className='w-full flex'>
              {/* Directory Structure */}
              {showFolderNav && (
                <FolderNavPath
                  folders={folderStack}
                  onFolderSelect={handleUpdateFolderStack}
                />
              )}
            </div>
            <EmptyState name='files' />
          </>
        ) : null}

        {currentFolder?.files && currentFolder.files?.length > 0 ? (
          <>
            {showSearchHits && (
              <FileSearchResults
                results={searchHits}
                onClick={handleSearchClick}
              />
            )}

            {showFolderNav && (
              <FolderNavPath
                folders={folderStack}
                onFolderSelect={handleUpdateFolderStack}
              />
            )}

            {showFolderList && (
              <Menu data-testid='file-browser-list'>
                {currentFolder.files.map(
                  (item) =>
                    shouldDisplayFileItem(auth, item) && (
                      <Fragment key={item.id}>
                        <FolderOrFileComponent
                          item={item}
                          onFolderSelect={(f) =>
                            handleUpdateFolderStack(f as Folder[])
                          }
                          onFileSelect={(f) => setCurrentFile(f as OSFile)}
                          openUpload={handdleOpenUpload}
                          currentFolderPath={currentFolderPath}
                          currentFolderIdPath={currentFolderIdPath}
                          firmClientsData={firmsClientsData}
                          firmUserIds={firmUserIds as number[]}
                        />
                      </Fragment>
                    )
                )}
              </Menu>
            )}
          </>
        ) : null}
      </div>
    </>
  );
}
export { FileBrowser };
