import PageContainer from '@/components/PageContainer';
import { DashboardWrapper } from '@/components/DashboardWrapper';

function InvestorReporting() {
  return (
    <PageContainer name='investor-account'>
      <DashboardWrapper>
        <h1>Investor Reporting</h1>
      </DashboardWrapper>
    </PageContainer>
  );
}

export default InvestorReporting;
