import { FileItem, FirmData } from '../../../../../shared/types/files';
import {
  FileItemExtended,
  CustomTabItemExtended,
  SyftItemExtended,
} from './interfaces';
import {
  ExtendedCustomTabItem,
  SyftProperties,
} from '../../../../../shared/types';
import { ClientConfig } from '../../../../../shared/types/client';

interface BaseTabItem {
  allowedUsers?: number[];
  allowedTags?: number[];
  allowedFirms?: FirmData[];
}

const excludedFolders = ['Vendor + Customer Docs', 'People + Payroll'];

export const transformTabToExtendedTab = <
  T extends BaseTabItem,
  U extends T & { isChecked: boolean }
>(
  config?: ClientConfig,
  userId?: number,
  tagId?: number,
  firmId?: number
): U[] => {
  const tabItems = config ? (JSON.parse(config.config_value) as T[]) : [];

  return tabItems.map((tabItem): U => {
    let isChecked = false;
    if (userId && tabItem.allowedUsers?.includes(userId)) {
      isChecked = true;
    }
    if (tagId && tabItem.allowedTags?.includes(tagId)) {
      isChecked = true;
    }
    if (
      firmId &&
      tabItem.allowedFirms?.map((firm) => firm.firmId).includes(firmId)
    ) {
      isChecked = true;
    }
    return { ...tabItem, isChecked } as U;
  });
};

// Usage example for transforming CustomTabItem to CustomTabItemExtended
export const transformCustomTabItemToCustomTabItemExtended = (
  customTabsConfig?: ClientConfig,
  userId?: number,
  tagId?: number,
  firmId?: number
) =>
  transformTabToExtendedTab<ExtendedCustomTabItem, CustomTabItemExtended>(
    customTabsConfig,
    userId,
    tagId,
    firmId
  );

// Usage example for transforming SyftProperties to SyftItemExtended
export const transformSyftTabToSyftTabExtended = (
  syftUrlConfig?: ClientConfig,
  userId?: number,
  tagId?: number,
  firmId?: number
) =>
  transformTabToExtendedTab<SyftProperties, SyftItemExtended>(
    syftUrlConfig,
    userId,
    tagId,
    firmId
  );

export const verifyCheckStatusRecursive = (
  folder: FileItem,
  userId?: number,
  tagId?: number,
  firmId?: number
): FileItemExtended => {
  const { permissions } = folder;
  let isChecked = false;
  if (userId && permissions && permissions?.length > 0) {
    if (permissions[0].allowedUsers?.includes(userId)) {
      isChecked = true;
    }
  }
  if (tagId && permissions && permissions?.length > 0) {
    if (permissions[0].allowedTags?.includes(tagId)) {
      isChecked = true;
    }
  }

  if (firmId && permissions && permissions.length > 0) {
    const allowedFirmIds = permissions[0].allowedFirms;
    if (allowedFirmIds && allowedFirmIds.includes(firmId)) {
      isChecked = true;
    }
  }

  const copyFolderChecked = folder.files?.map((subFolder) => {
    return verifyCheckStatusRecursive(subFolder, userId, tagId, firmId);
  });
  return { ...folder, isChecked, files: copyFolderChecked } as FileItemExtended;
};

export const transformFileItemtoFileItemExtended = (
  folders: FileItem,
  userId?: number,
  tagId?: number,
  firmId?: number
) => {
  const clientFolders = {
    ...folders,
    files: folders.files?.filter(
      (file) => !excludedFolders.includes(file.name)
    ),
  };
  const clientFolderExtended = clientFolders.files?.map((folder) => {
    return verifyCheckStatusRecursive(folder, userId, tagId, firmId);
  });
  return {
    ...(clientFolders as FileItemExtended),
    files: clientFolderExtended,
  } as FileItemExtended;
};

export const getOnlySelectedFolders = (files: FileItemExtended | null) => {
  const copyFilesState = { ...files };
  let selectedFiles = copyFilesState?.files?.filter(
    (file: FileItemExtended) =>
      file.isChecked ||
      (file.files &&
        file.files.filter((folder: FileItemExtended) => folder.isChecked)
          .length > 0)
  );

  selectedFiles = selectedFiles?.map((folder) => {
    const copyFolder = { ...folder };
    copyFolder.files = copyFolder.files?.filter(
      (file: FileItemExtended) => file.isChecked
    );
    return copyFolder;
  });
  copyFilesState.files = selectedFiles;
  return copyFilesState;
};

export const hasSubItems = (file: FileItemExtended) => {
  return file?.files ? file.files.length > 0 : false;
};

export const setIsCheckedRecursive = (
  folder: FileItemExtended,
  isCheckedValue: boolean,
  avoidSubFolders?: boolean
) => {
  return folder.files?.map((subFolder: FileItemExtended) => {
    const copySubFolder = { ...subFolder } as FileItemExtended;
    if (subFolder.type === 'file' || !avoidSubFolders) {
      copySubFolder.isChecked = isCheckedValue;
    }
    if (
      copySubFolder.files &&
      copySubFolder.files.length > 0 &&
      !avoidSubFolders
    ) {
      copySubFolder.files = setIsCheckedRecursive(
        copySubFolder,
        isCheckedValue,
        avoidSubFolders
      );
    }
    return copySubFolder;
  });
};

export const checkRecursive = (
  folder: FileItemExtended,
  fileId: string,
  setCheckedValue?: boolean,
  avoidSubFolders?: boolean
) => {
  const copyFolder = { ...folder };
  if (copyFolder.id === fileId) {
    const isCheckedValue =
      setCheckedValue === undefined ? !copyFolder.isChecked : setCheckedValue;
    copyFolder.isChecked = isCheckedValue;
    copyFolder.files = setIsCheckedRecursive(
      copyFolder,
      isCheckedValue,
      avoidSubFolders
    );
  } else {
    copyFolder.files = copyFolder.files?.map((subFolder: FileItemExtended) => {
      const copySubFolder = { ...subFolder };
      return checkRecursive(
        copySubFolder,
        fileId,
        setCheckedValue,
        avoidSubFolders
      );
    });
  }
  if (copyFolder.files?.some((f: FileItemExtended) => f.isChecked)) {
    copyFolder.isChecked = true;
  }
  return copyFolder;
};
export const setFilesIsChecked = (
  rootFolder: FileItemExtended,
  fileId: string,
  setCheckedValue?: boolean,
  avoidSubFolders?: boolean
) => {
  const copyFilesState = { ...rootFolder };
  copyFilesState.files = copyFilesState?.files?.map(
    (folder: FileItemExtended) => {
      return checkRecursive(folder, fileId, setCheckedValue, avoidSubFolders);
    }
  );
  return copyFilesState as FileItemExtended;
};

export const getCheckedFileIdRecursive = (
  filesState: FileItemExtended,
  listSelectedFolders: string[]
) => {
  const copyFilesState = { ...filesState } as FileItemExtended;
  copyFilesState.files?.forEach((subFolder: FileItemExtended) => {
    if (subFolder.isChecked) {
      listSelectedFolders.push(subFolder.id);
    }
    getCheckedFileIdRecursive(subFolder, listSelectedFolders);
  });
  return listSelectedFolders;
};
